import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CasinoCard from "./CasinoCard";
import Slider from "react-slick";

import Promotion1 from "../../assets/images/banner/promotion1.jpg";
import Promotion2 from "../../assets/images/banner/promotion2.jpg";
import Promotion3 from "../../assets/images/banner/promotion3.jpg";
import { casinoTabs } from "../../lib/data/casinoTabs.data";
import { useSelector } from "react-redux";

const RightBarCasino = () => {
  const navigate = useNavigate();
  const categoriesTab = [
    { name: "Providers", key: "providers", requiredAuth: true },
    { name: "Live", key: "liveGames" },
    // { name: "Virtual", key: "virtualGames" },
  ];
  const [activeCategory, setActiveCategory] = useState("liveGames");
  const { isAuth } = useSelector((state) => state.auth);

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const checkIfDisplayRender = (tab) => {
    if (tab?.requiredAuth === true) {
      return isAuth === true;
    }
    return true;
  };

  return (
    <>
      <div className="rightbarSec">
        <div className="PromotionContent">
          <div className="PromotionSection">
            <h3>Promotions</h3>
          </div>
          <div className="SliderPromotion">
            <Slider {...settings}>
              <div>
                <img src={Promotion1} alt="" />
              </div>
              <div>
                <img src={Promotion2} alt="" />
              </div>
              <div>
                <img src={Promotion3} alt="" />
              </div>
            </Slider>
          </div>
        </div>
        <ul className="tabs">
          {categoriesTab.map((tab, index) => (
            <>
              {(checkIfDisplayRender(tab) && (
                <li
                  key={index}
                  onClick={() => {
                    if (tab?.redirectUrl) {
                      navigate(tab?.redirectUrl);
                    } else {
                      setActiveCategory(tab.key);
                    }
                  }}
                >
                  <a
                    href="javascript:void(0)"
                    style={
                      activeCategory === tab.key
                        ? {
                            // background: "#ff3131",
                            color: "#ffffff",
                          }
                        : {}
                    }
                  >
                    {tab.name}
                  </a>
                </li>
              )) ||
                null}
            </>
          ))}
        </ul>

        <ul className="games">
          {casinoTabs &&
            casinoTabs?.[activeCategory] &&
            Object.values(casinoTabs?.[activeCategory])
              .filter((f) => typeof f != "string")
              .map((game) => {
                return Object.values(game).map((item, idx) => {
                  return <CasinoCard key={"casinogame" + idx} data={item} />;
                });
              })}
        </ul>
      </div>
    </>
  );
};

export default RightBarCasino;
