import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { casinoTabsNew as casinoData } from "../../../../lib/data/casinoNew.data";

const STANDARD_ALL_KEY = "all";

const GAME_STATUS = [
  { id: 1, name: "All", key: STANDARD_ALL_KEY },
  { id: 2, name: "New", key: "new" },
  { id: 3, name: "Live", key: "live" },
  { id: 4, name: "Virtual", key: "virtual" },
  { id: 5, name: "Popular", key: "popular" },
];

const PROVIDERS = [
  { id: 1, name: "All", key: STANDARD_ALL_KEY },
  { id: 2, name: "Ezugi", key: "ezugi" },
  { id: 3, name: "Evolution", key: "evolution" },
];

const GAMES_CATEGORY = [
  { id: 1, name: "All", key: STANDARD_ALL_KEY },
  { id: 1, name: "Andarbahar", key: "andarbahartab" },
  { id: 1, name: "Roulette", key: "roulettetab" },
  { id: 1, name: "Dragontiger", key: "dragontigertab" },
  { id: 1, name: "Baccarat", key: "baccarattab" },
  { id: 1, name: "Blackjack", key: "blackjacktab" },
  { id: 1, name: "Poker", key: "pokertab" },
  { id: 1, name: "Spribe", key: "spribe" },
  // { id: 1, name: "Supernowa", key: "supernowa" },
  { id: 1, name: "Vivo", key: "vivo" },
];

const CategorisedCasino = () => {
  const [activeGameStatus, setActiveGameStatus] = useState(GAME_STATUS[0]);
  const [activeProvider, setActiveProvider] = useState(PROVIDERS[0]);
  const [activeCategory, setActiveCategory] = useState(GAMES_CATEGORY[0]);

  const filterCategoryAndProvider = (
    dataToFilter = [],
    filterKey = "providerName"
  ) => {
    const filterState =
      filterKey === "providerName" ? activeProvider : activeGameStatus;
    const filterProvider =
      filterState.key === STANDARD_ALL_KEY
        ? dataToFilter.filter((f) => typeof f !== "string")
        : dataToFilter.filter(
            (f) =>
              typeof f !== "string" &&
              f?.[filterKey] &&
              f?.[filterKey]?.toLowerCase() === filterState.key
          );

    return filterProvider || [];
  };

  return (
    <>
      <div className="CasinoPageSection">
        <div style={{ display: "flex" }} className="CasinoTabsSection">
          <ul style={{ display: "flex" }} className="CasinoTabs">
            {GAME_STATUS.map((status, idx) => {
              return (
                <li
                  key={idx}
                  style={
                    status.key === activeGameStatus.key
                      ? {
                          paddingLeft: "5px",
                          color: "#ffc300",
                          border: "1px solid #ffc300",
                        }
                      : { paddingLeft: "5px" }
                  }
                  onClick={() => setActiveGameStatus(status)}
                >
                  {status.name}
                </li>
              );
            })}
          </ul>

          <div style={{ display: "flex" }} className="CasinoDropDown">
            <Dropdown
              onSelect={(eventKey) => {
                setActiveProvider(PROVIDERS.find((f) => f.key === eventKey));
              }}
            >
              <Dropdown.Toggle variant="success" id="provider-dropdown">
                {activeProvider.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {PROVIDERS.map((provider, idx) => {
                  return (
                    <Dropdown.Item key={idx} eventKey={provider.key}>
                      {provider.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown
              onSelect={(eventKey) => {
                setActiveCategory(
                  GAMES_CATEGORY.find((f) => f.key === eventKey)
                );
              }}
            >
              <Dropdown.Toggle variant="success" id="provider-dropdown">
                {activeCategory.name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {GAMES_CATEGORY.map((category, idx) => {
                  return (
                    <Dropdown.Item key={idx} eventKey={category.key}>
                      {category.name}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <ul style={{ display: "flex" }} className="CasinoGameSection">
          {activeCategory.key !== STANDARD_ALL_KEY
            ? casinoData &&
              casinoData?.[activeCategory?.key] &&
              filterCategoryAndProvider(
                filterCategoryAndProvider(
                  Object.values(casinoData?.[activeCategory?.key]),
                  "gameStatus"
                ),
                "providerName"
              ).map((game, idx) => {
                return (
                  <li key={idx}>
                    <a href={game?.redirectUrl}>
                      <img src={game?.imgUrl} />

                      <p>{game?.name}</p>
                      <p>{game.providerName}</p>
                    </a>
                    <span className="focus-border">
                      <i></i>
                    </span>
                  </li>
                );
              })
            : casinoData &&
              Object.values(casinoData).map((casino) => {
                return filterCategoryAndProvider(
                  filterCategoryAndProvider(
                    Object.values(casino),
                    "gameStatus"
                  ),
                  "providerName"
                ).map((game, idx) => {
                  return (
                    <li key={idx}>
                      <a href={game?.redirectUrl}>
                        <img src={game?.imgUrl} />

                        <p>{game?.name}</p>
                        <p className="ProviderName">{game.providerName}</p>
                      </a>
                      <span className="focus-border">
                        <i></i>
                      </span>
                    </li>
                  );
                });
              })}
        </ul>
      </div>
    </>
  );
};
export default CategorisedCasino;
