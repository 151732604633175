export const casinoTabsNew = {
  andarbahartab: {
    AndarBahar: {
      redirectUrl: "/casino/ezg-andar-bahar",
      code: "228000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/AndarBahar.jpg",
      alt: "",
      name: "Andar Bahar",
      providerName: "Ezugi",
      gameStatus: "popular",
    },
    UltimateAndarBahar: {
      redirectUrl: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/UltimateAndarBahar.jpg",
      alt: "",
      name: "Ultimate Andar Bahar",
      providerName: "Ezugi",
      gameStatus: "popular",
    },
    OTTAndarBahar: {
      redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
      code: "328000",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/andarbahar/OTTAndarBahar.jpg",
      alt: "",
      name: "Casino Marina Andar Bahar",
      providerName: "Ezugi",
      gameStatus: "popular",
    },
    name: "andarbahartab",
  },

  roulettetab: {
    AutoRoulette: {
      redirectUrl: "/casino/ezg-auto-roulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/AutoRoulette.jpg",
      alt: "",
      name: "Auto Roulette",
      providerName: "Ezugi",
      gameStatus: "popular",
    },
    SpeedAutoRoulette: {
      redirectUrl: "/casino/ezg-speed-auto-roulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Speedautoroullete.jpg",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Ezugi",
      gameStatus: "popular",
    },
    NamasteRoulette: {
      redirectUrl: "/casino/ezg-namaste-roulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Namasteroulette.jpg",
      alt: "",
      name: "Namaste Roulette",
      providerName: "Ezugi",
      gameStatus: "live",
    },
    UltimateRoulette: {
      redirectUrl: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/UltimateRoulette.jpg",
      alt: "",
      name: "Ultimate Roulette",
      providerName: "Ezugi",
      gameStatus: "popular",
    },
    AutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/AutorouletteEvo.jpg",
      alt: "",
      name: "Auto Roulette-EVO",
      providerName: "Evolutio",
      gameStatus: "live",
    },
    LightningRoulette: {
      redirectUrl: "/casino/ezgevo-lightning-roulette",
      code: "1000092",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Lightiningroulette.jpg",
      alt: "",
      name: "Lightning Roulette",
      providerName: "Evolutio",
      gameStatus: "live",
    },
    SpeedAutoRouletteEvo: {
      redirectUrl: "/casino/ezgevo-speed-auto-roulette",
      code: "1000103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/roulette/Speedautoroullete.jpg",
      alt: "",
      name: "Speed Auto Roulette",
      providerName: "Evolutio",
      gameStatus: "popular",
    },
    name: "roulettetab",
  },

  dragontigertab: {
    DragonTiger: {
      redirectUrl: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "Ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragontiger/DragonTiger.jpg",
      alt: "",
      name: "Dragon Tiger",
      providerName: "Ezugi",
      gameStatus: "virtual",
    },
    DragonTigerEvo: {
      redirectUrl: "/casino/ezgevo-dragon-tiger-evo",
      code: "",
      casino: "Evolution",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/dragontiger/DragonTigerEvo.jpg",
      alt: "",
      name: "Dragon Tiger EVO",
      providerName: "Ezugi",
      gameStatus: "virtual",
    },
    name: "dragontigertab",
  },

  baccarattab: {
    Baccarat: {
      redirectUrl: "/casino/ezg-baccarat",
      code: "100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Baccarat01.jpg",
      alt: "",
      name: "Baccarat",
      providerName: "Ezugi",
      gameStatus: "virtual",
    },
    FortuneBaccarat: {
      redirectUrl: "/casino/ezg-fortune-baccarat",
      code: "102",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/FortuneBaccarat.jpg",
      alt: "",
      name: "Fortune Baccarat",
      providerName: "Ezugi",
      gameStatus: "live",
    },
    NoComBaccarat: {
      redirectUrl: "/casino/ezg-no-commission-baccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/nocommissionbaccarat.jpg",
      alt: "",
      name: "No Commission Baccarat",
      providerName: "Ezugi",
      gameStatus: "live",
    },
    SpeedCricketBaccarat: {
      redirectUrl: "/casino/ezg-speed-cricket-baccarat",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/baccarat/Speedcricketbaccarat.jpg",
      alt: "",
      name: "Speed Cricket Baccarat",
      providerName: "Ezugi",
      gameStatus: "live",
    },
    // SpeedBaccaratA: {
    //   redirectUrl: "/casino/ezgevo-speed-baccarat-a",
    //   code: "1000021",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/baccarat/sPEEDBACCARATA.jpg",
    //   alt: "",
    //   name: "Speed Baccarat A",
    //   providerName: "Evolution",
    // },
    // NoComBaccaratEvo: {
    //   redirectUrl: "/casino/ezgevo-no-commission-baccarat-evo",
    //   code: "1000018",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: "http://localhost:3000/Casino",
    //   imgUrl: "./images/baccarat/NoComBaccaratEvo.jpg",
    //   alt: "",
    //   name: "No Commission Baccarat-EVO",
    //   providerName: "Evolution",
    // },
    name: "baccarattab",
  },

  blackjacktab: {
    BlackjackSilverD: {
      redirectUrl: "/casino/ezgevo-blackjack-silver-d",
      code: "1000044",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-Silver-D.jpg",
      alt: "",
      name: "Blackjack Silver D",
      providerName: "Evolution",
      gameStatus: "popular",
    },
    BlackjackVIPE: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-e",
      code: "1000052",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-VIP-E.jpg",
      alt: "",
      name: "Blackjack VIP E",
      providerName: "Evolution",
      gameStatus: "live",
    },
    SalonPriveBlackjackC: {
      redirectUrl: "/casino/ezg-salon-prive-blackjack",
      code: "1000070",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Salon-Prive-Blackjack-C.jpg",
      alt: "",
      name: "Salon Prive Blackjack",
      providerName: "Evolution",
      gameStatus: "live",
    },
    SpeedBlackjackD: {
      redirectUrl: "/casino/ezgevo-speed-blackjack-d",
      code: "1000033",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Speed-Blackjack-D1.jpg",
      alt: "",
      name: "Speed Blackjack D",
      gameStatus: "live",
      providerName: "Evolution",
    },
    BlackjackVIPL: {
      redirectUrl: "/casino/ezgevo-blackjack-vip-l",
      code: "1000056",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-VIP-L.jpg",
      alt: "",
      name: "Blackjack VIP L",
      providerName: "Evolution",
      gameStatus: "virtual",
    },
    BlackjackGold3: {
      redirectUrl: "/casino/ez-blackjack-gold-3",
      code: "203",
      casino: "Ezugi-WCO",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Blackjack-Gold-3.jpg",
      alt: "",
      name: " Blackjack Gold 3",
      providerName: "Ezugi",
      gameStatus: "virtual",
    },
    ClassicSpeedBlackjack3: {
      redirectUrl: "/casino/ezgevo-classic-speed-blackjack-3",
      code: "1000228",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/blackjack/Speed-Blackjack-3.jpg",
      alt: "",
      name: " Classic Speed Blackjack 3  ",
      providerName: "Evolution",
      gameStatus: "virtual",
    },
    name: "blackjacktab",
  },

  pokertab: {
    Hand2CasinoHoldem: {
      redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
      code: "1000073",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/2handcasinoholem.jpg",
      alt: "",
      name: "2 Hand Casino Holdem",
      providerName: "Evolution",
      gameStatus: "new",
    },
    BetonTeenPatti: {
      redirectUrl: "/casino/ezg-bet-on-teen-patti",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Betonteenpatti.jpg",
      alt: "",
      name: "Bet on Teen Patti",
      providerName: "Ezugi",
      gameStatus: "live",
    },
    Onedayteenpatti1: {
      redirectUrl: "/casino/ezg-one-day-teen-patti",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Onedayteenpatti.jpg",
      alt: "",
      name: "One Day Teen Patti",
      providerName: "Ezugi",
      gameStatus: "popular",
    },
    SideBetCity: {
      redirectUrl: "/casino/ezgevo-side-bet-city",
      code: "1000115",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Sidebetcity.jpg",
      alt: "",
      name: "Side Bet City",
      providerName: "Evolution",
      gameStatus: "virtual",
    },
    TeenPatti3card: {
      redirectUrl: "/casino/ezg-teen-patti-3-card",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/Teenpatti3card.jpg",
      alt: "",
      name: "Teen Patti 3 Card",
      providerName: "Ezugi",
      gameStatus: "popular",
    },
    Ultimatetexasholdem: {
      redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
      code: "1000151",
      casino: "ezugi",
      provider: "",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/poker/ultimatetaxasholden.jpg",
      alt: "",
      name: "Ultimate Texas Holdem",
      providerName: "Evolution",
      gameStatus: "virtual",
    },
    name: "pokertab",
  },

  spribe: {
    aviator: {
      redirectUrl: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Aviator1.jpg",
      alt: "",
      name: "Aviator",
      providerName: "Spribe",
      gameStatus: "new",
    },
    mines: {
      redirectUrl: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Mines.jpg",
      alt: "",
      name: "Mines",
      providerName: "Spribe",
      gameStatus: "new",
    },
    goal: {
      redirectUrl: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Goal.jpg",
      alt: "",
      name: "Goal",
      providerName: "Spribe",
      gameStatus: "new",
    },
    dice: {
      redirectUrl: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Dice.jpg",
      alt: "",
      name: "Dice",
      providerName: "Spribe",
      gameStatus: "new",
    },
    plinko: {
      redirectUrl: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Plinko.jpg",
      alt: "",
      name: "Plinko",
      providerName: "Spribe",
      gameStatus: "new",
    },
    miniroulette: {
      redirectUrl: "/casino/spribe/miniroulette",
      code: "mini-roulette",
      casino: "spribe",
      provider: "mini-roulette",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/MiniRoulette.jpg",
      alt: "",
      name: "Mini Roulette",
      providerName: "Spribe",
      gameStatus: "new",
    },
    hilo: {
      redirectUrl: "/casino/spribe/hilo",
      code: "hi-lo",
      casino: "spribe",
      provider: "hi-lo",
      homeUrl: "http://localhost:3000/Casino",
      imgUrl: "./images/spribe/Hilo1.jpg",
      alt: "",
      name: "Hilo",
      providerName: "Spribe",
      gameStatus: "new",
    },
    name: "spribe",
  },

  // supernowa: {
  //   RNGCasinoQueen: {
  //     redirectUrl: "/casino/sn-rng-casino-queen",
  //     code: "RCQ",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGCasinoQueen.jpg",
  //     alt: "",
  //     name: "RNG Casino Queen",
  //     providerName: "Supernowa",
  //     gameStatus: "new",
  //   },
  //   RNGCasinoQueen2020: {
  //     redirectUrl: "/casino/sn-rng-casino-queen-2020",
  //     code: "RCQ20",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGCasinoQueen2020.jpg",
  //     alt: "",
  //     name: "RNG Casino Queen 2020",
  //     providerName: "Supernowa",
  //     gameStatus: "new",
  //   },
  //   // RNGCricket2020: {
  //   //   redirectUrl: "/casino/sn-rng-cricket-2020",
  //   //   code: "RCKT20",
  //   //   casino: "wco",
  //   //   provider: "SN",
  //   //   homeUrl: "http://localhost:3000/Casino",
  //   //   imgUrl: "./images/supernowa/RNGCricket2020.jpg",
  //   //   alt: "",
  //   //   name: "RNG Cricket 2020",
  //   //   providerName: "Supernowa",
  //   // },
  //   RNGDragonTiger: {
  //     redirectUrl: "/casino/sn-rng-dragon-tiger",
  //     code: "VDT",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNG-Dragon-Tiger.jpg",
  //     alt: "",
  //     name: "RNG Dragon Tiger",
  //     providerName: "Supernowa",
  //     gameStatus: "new",
  //   },
  //   RNGDragonTiger2020: {
  //     redirectUrl: "/casino/sn-rng-dragon-tiger-2020",
  //     code: "VDT20",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGDragonTiger2020.jpg",
  //     alt: "",
  //     name: "RNG Dragon Tiger 2020",
  //     providerName: "Supernowa",
  //     gameStatus: "virtual",
  //   },
  //   RNGJoker: {
  //     redirectUrl: "/casino/sn-rng-joker",
  //     code: "VJKR",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGJoker.jpg",
  //     alt: "",
  //     name: "RNG Joker",
  //     providerName: "Supernowa",
  //     gameStatus: "popular",
  //   },
  //   RNGKingRace: {
  //     redirectUrl: "/casino/sn-rng-king-race",
  //     code: "VCR",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGKingRace.jpg",
  //     alt: "",
  //     name: "RNG King Race",
  //     providerName: "Supernowa",
  //     gameStatus: "new",
  //   },
  //   RNGLucky7: {
  //     redirectUrl: "/casino/sn-rng-lucky-7",
  //     code: "VUD7",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGLucky7.jpg",
  //     alt: "",
  //     name: "RNG Lucky 7",
  //     providerName: "Supernowa",
  //     gameStatus: "new",
  //   },
  //   RNGTeenPatti: {
  //     redirectUrl: "/casino/sn-rng-teen-patti",
  //     code: "VTP",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGTeenPatti.jpg",
  //     alt: "",
  //     name: "RNG Teen Patti",
  //     providerName: "Supernowa",
  //     gameStatus: "popular",
  //   },
  //   RNGTeenPatti2020: {
  //     redirectUrl: "/casino/sn-rng-teen-patti-2020",
  //     code: "VTP20",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGTeenPatti2020.jpg",
  //     alt: "",
  //     name: "RNG Teen Patti 2020",
  //     providerName: "Supernowa",
  //     gameStatus: "new",
  //   },
  //   RNGWorliMatka: {
  //     redirectUrl: "/casino/sn-rng-worli-matka",
  //     code: "VWM",
  //     casino: "wco",
  //     provider: "SN",
  //     homeUrl: "http://localhost:3000/Casino",
  //     imgUrl: "./images/supernowa/RNGWorliMatka.jpg",
  //     alt: "",
  //     name: "RNG Worli Matka",
  //     providerName: "Supernowa",
  //     gameStatus: "virtual",
  //   },
  //   name: "supernowa",
  // },

  vivo: {
    VABacarat1: {
      redirectUrl: "/casino/vivo-va-bacarat-1",
      imgUrl: "./images/Vivo/VABacarat1.jpg",
      name: "VA Bacarat 1",
      providerName: "Vivo",
      gameStatus: "live",
    },
    VABacarat2: {
      redirectUrl: "/casino/vivo-va-bacarat-2",
      imgUrl: "./images/Vivo/VABacarat2.jpg",
      name: "VA Bacarat 2",
      providerName: "Vivo",
      gameStatus: "popular",
    },
    VABacarat3: {
      redirectUrl: "/casino/vivo-va-bacarat-3",
      imgUrl: "./images/Vivo/VABacarat3.jpg",
      name: "VA Bacarat 3",
      providerName: "Vivo",
      gameStatus: "new",
    },
    VABacarat4: {
      redirectUrl: "/casino/vivo-va-bacarat-4",
      imgUrl: "./images/Vivo/VABacarat4.jpg",
      name: "VA Bacarat 4",
      providerName: "Vivo",
      gameStatus: "virtual",
    },
    VABacarat5: {
      redirectUrl: "/casino/vivo-va-bacarat-5",
      imgUrl: "./images/Vivo/VABacarat5.jpg",
      name: "VA Bacarat 5",
      providerName: "Vivo",
      gameStatus: "new",
    },
    OracleBlazeRoulette: {
      redirectUrl: "/casino/vivo-oracle-blaze-roulette",
      imgUrl: "./images/Vivo/OracleBlazeRoulette.jpg",
      name: "Oracle Blaze Roulette",
      providerName: "Vivo",
      gameStatus: "live",
    },
    PortomasoRoulette: {
      redirectUrl: "/casino/vivo-portomaso-roulette",
      imgUrl: "./images/Vivo/PortomasoRoulette.jpg",
      name: "Portomaso Roulette",
      providerName: "Vivo",
      gameStatus: "new",
    },
    LasVegasRoulette: {
      redirectUrl: "/casino/vivo-las-vegas-roulette",
      imgUrl: "./images/Vivo/LasVegasRoulette.jpg",
      name: "Las Vegas Roulette",
      providerName: "Vivo",
      gameStatus: "live",
    },
    MacauBaccarat: {
      redirectUrl: "/casino/vivo-macau-baccarat",
      imgUrl: "./images/Vivo/MacauBaccarat.jpg",
      name: "Macau Baccarat",
      providerName: "Vivo",
      gameStatus: "popular",
    },
    LasVegasBlackjack: {
      redirectUrl: "/casino/vivo-las-vegas-blackjack",
      imgUrl: "./images/Vivo/lasvegasblackjack.jpg",
      name: "Las Vegas Blackjack",
      providerName: "Vivo",
      gameStatus: "new",
    },
    DragonTiger: {
      redirectUrl: "/casino/vivo-dragon-tiger",
      imgUrl: "./images/Vivo/DragonTiger.jpg",
      name: "Dragon Tiger",
      providerName: "Vivo",
      gameStatus: "virtual",
    },
    TeenPattiVivo: {
      redirectUrl: "/casino/vivo-teen-patti",
      imgUrl: "./images/Vivo/TeenPatti.jpg",
      name: "Teen Patti",
      providerName: "Vivo",
      gameStatus: "virtual",
    },
    AndarBaharVivo: {
      redirectUrl: "/casino/vivo-andar-bahar",
      imgUrl: "./images/Vivo/AndarBaharVivo.jpg",
      name: "Andar Bahar",
      providerName: "Vivo",
      gameStatus: "virtual",
    },
    name: "vivo",
  },
};
