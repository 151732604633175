import React, { forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

import { logoutUser } from "../redux/auth/actions";

import HomeIcon from "../assets/images/icons/leftbar/home.png";
import InplayIcon from "../assets/images/icons/leftbar/inplay.png";
import CricketIcon from "../assets/images/icons/leftbar/cricket.png";
import SoccerIcon from "../assets/images/icons/leftbar/soccer.png";
import TennisIcon from "../assets/images/icons/leftbar/tennis.png";
import aviator from "../assets/images/icons/leftbar/aviator.png";
import CasinoIcon from "../assets/images/icons/leftbar/ezugi.png";
import EvolutionIcon from "../assets/images/icons/leftbar/evolution.png";
import VivoIcon from "../assets/images/icons/leftbar/vivo.png";
import SupernowaIcon from "../assets/images/icons/leftbar/supernowa.png";
import xpgIcon from "../assets/images/icons/leftbar/xpg.png";
import WorldcasinoIcon from "../assets/images/icons/leftbar/worldcasino.png";
import SlotsIcon from "../assets/images/icons/leftbar/slots.png";

import horseracingIcon from "../assets/images/games-icon/horseracing-icon.png";
import SnookerIcon from "../assets/images/games-icon/snooker-icon.png";
import GreyhoundIcon from "../assets/images/games-icon/greyhoundracing-icon.png";
import PoliticsIcon from "../assets/images/games-icon/politics-icon.png";

import UserIcon from "../assets/images/user-icon.svg";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/telegram.svg";
import Instagram from "../assets/images/Instagram.svg";
import Facebook from "../assets/images/facebook-512.svg";
import { APP_CONST } from "../config/const";
import { isMobile } from "react-device-detect";

import WalletIcon from "../assets/images/icons/wallet-icon.png";
import ProfileIcon from "../assets/images/icons/menus/user.png";
import MyBetsIcon from "../assets/images/icons/menus/my-bets.png";
import BonusIcon from "../assets/images/icons/menus/bonus.png";
import ReferralIcon from "../assets/images/icons/menus/referral.png";
import AcStatementIcon from "../assets/images/icons/menus/statement.png";
import LogoutIcon from "../assets/images/icons/menus/logout.png";

import PromotionIcon from "../assets/images/icons/leftbar/promotion.png";
import Greyhoundracing from "../assets/images/icons/leftbar/greyhound-racing.png";
import Horseracing from "../assets/images/icons/leftbar/horse-racing.png";
import Basketball from "../assets/images/icons/leftbar/basketball.png";
import Politics from "../assets/images/icons/leftbar/politics.png";
import TableTennis from "../assets/images/icons/leftbar/table-tennis-equipment.png";
import IceHockey from "../assets/images/icons/leftbar/ice-hockey.png";
import Archery from "../assets/images/icons/leftbar/target.png";
import MotorSports from "../assets/images/icons/leftbar/motorcycle.png";

const MobLeftbar = forwardRef((props, ref) => {
  console.log("ref", ref);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { sportsData } = useSelector((state) => state.sports);
  const { isAuth } = useSelector((state) => state.auth);
  const { appDetails } = useSelector((state) => state.app);

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const handleModalShow = (modalName) => {
    props.setShowAuthModals(true);
  };
  const handleModalClose = (modalName) => {
    props.setShowAuthModals(false);
  };

  const { user, balance, wallet } = useSelector((state) => state.auth);

  return (
    <div className="mobleftbarPage">
      <div className="leftbarSec" ref={ref}>
        <ul>
          <li className="promotion">
            <a href="/promotion">
              <img src={PromotionIcon} alt="Home Icon" />
              <span>Promotion</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                navigate("/sports");
              }}
            >
              <img src={HomeIcon} alt="Home Icon" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Inplay");
              }}
            >
              <img src={InplayIcon} alt="Inplay Icon" />
              <span>In-play</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Cricket");
              }}
            >
              <img src={CricketIcon} alt="Cricket Icon" />
              <span>Cricket</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Soccer");
              }}
            >
              <img src={SoccerIcon} alt="Soccer Icon" />
              <span>Soccer</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Tennis");
              }}
            >
              <img src={TennisIcon} alt=" Icon" />
              <span>Tennis</span>
            </a>
          </li>

          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Ice%20Hockey");
              }}
            >
              <img src={IceHockey} alt=" Icon" />
              <span>Ice Hockey</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Motor%20Sports");
              }}
            >
              <img src={MotorSports} alt=" Icon" />
              <span>Motor Sports</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Archery");
              }}
            >
              <img src={Archery} alt=" Icon" />
              <span>Archery</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Basketball");
              }}
            >
              <img src={Basketball} alt=" Icon" />
              <span>Basketball</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Politics");
              }}
            >
              <img src={Politics} alt=" Icon" />
              <span>Politics</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Horse%20Racing");
              }}
            >
              <img src={Horseracing} alt=" Icon" />
              <span>Horse Racing</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/GreyHond%20Racing");
              }}
            >
              <img src={Greyhoundracing} alt=" Icon" />
              <span>Grey Hound Racing</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default MobLeftbar;
