import React, { useState } from "react";
import { forgotPassword } from "../../../redux/auth/actions";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import EyeIcon from "../../../../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import parsePhoneNumber from "libphonenumber-js";
import { Toaster } from "../../../utils/toaster";
import { APP_CONST } from "../../../config/const";
import { Modal } from "react-bootstrap";
import LoginImg from "../../../assets/images/banner/login-img-new.png";

import { useSelector } from "react-redux";

const ForgotPasswords = ({ show, handleClose, handleModalShow }) => {
  const { appDetails } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState("");
  const handleSubmit = async () => {
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.phone = phoneNumber.nationalNumber;
      payload.countryCode = phoneNumber.countryCallingCode;
      forgotPassword(payload)
        .then((res) => {
          if (res.data.error === false) {
            Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "OTP sent");
            // navigate("/reset-password");
            handleClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD, "auto");
            handleModalShow(APP_CONST.AUTH_MODAL.RESET_PASSWORD);
          }
        })
        .catch((err) => {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, err.response.data.message);
        });
    } else {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, "Please enter mobile number");
    }
  };

  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header
        closeButton
        onClick={() =>
          handleClose(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD, "manual")
        }
        closeVariant="white"
      >
        <Modal.Title>
          {" "}
          <img
            className="login-modal-img"
            src={appDetails?.LOGO_URL}
            alt="Logo"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          <div className="Login-leftPart">
            <img src={LoginImg} alt="LoginImg" />
          </div>

          <div className="Login-rightPart">
            <div className="form-steps">
              <h2 className="h2">Hello Again!</h2>
              <p>
                Welcome back, you've
                <br />
                been missed!
              </p>
              <Form>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <PhoneInput
                    country={"in"}
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    // localization={es}
                    onlyCountries={["in"]}
                    inputStyle={{
                      width: "100%",
                      padding: "0.7rem 0.75rem",
                      fontSize: "0.86rem",
                      paddingLeft: "50px",
                      minHeight: "41px",
                    }}
                    onChange={(phone) => {
                      // alert("phone", phone);
                      // alert(phone);
                      setMobileNumber(phone);
                    }}
                  />
                </Form.Group>
              </Form>

              <Button
                className="btn_primary"
                variant=""
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ForgotPasswords;
