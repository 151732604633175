import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import { Footer } from "@mantine/core";
import CasinoCard from "../../../components/CasinoCard";
import { casinoTabs } from "../../../../lib/data";
import BackIcon from "../../../../assets/images/back-icon.png";
import { useNavigate } from "react-router-dom";

const Spribe = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main mt-4">
        <div className=" container">
          <div className="headTitle mt-3">
            <div className="back-btn">
              <a href="javascript:void(0)" onClick={() => navigate(-1)}>
                <img src={BackIcon} alt="back" width={28} />
              </a>
            </div>

            <div className="title">
              <h3>Spribe</h3>
            </div>
          </div>

          <div className="casinoProvidersGames SlotsGameSection SpribeGameSection mt-4">
            {Array.isArray(Object.values(casinoTabs?.spribe.slide1)) &&
              Object.values(casinoTabs?.spribe.slide1)
                .slice(0, 7)
                .map((game, idx) => (
                  <>
                    {console.log("game", game)}
                    <CasinoCard key={"casinogame" + idx} data={game} />
                  </>
                ))}
          </div>
        </div>
      </main>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Spribe;
