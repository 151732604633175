import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "../assets/images/icons/header/logo.png";
import ExchangeIcon from "../assets/images/icons/header/inplay.png";
import EzugiIcon from "../assets/images/icons/header/Casino1.png";
import SportsIcon from "../assets/images/icons/header/exchange1.png";
import { useSelector } from "react-redux";
import UserIcon from "../assets/images/icons/header/Users.png";
import { APP_CONST } from "../config/const";
import ModalWrapper from "./ModalWrapper";

export default function BottomNav() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activePage, setActivePage] = useState("");

  const { isAuth } = useSelector((state) => state.auth);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");

  const footerPages = [
    "home",
    "sportsbook",
    "evolution",
    "casino",
    "deposit",
    "aviator",
    "sports",
    "/",
  ];
  useEffect(() => {
    const matchedValue = footerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  const handleModalShow = () => {
    if (setShowAuthModals) {
      setShowAuthModals(true);
    }
  };

  const handleRedirections = (url) => {
    if (isAuth) {
      navigate(url);
    } else {
      setShowAuthModals(url);
    }
  };
  return (
    <>
      <div className="footer-menu d-md-none position-fixed start-0 bottom-0 z-index-2 w-100 ">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            <a
              className={activePage === "evolution" ? "active" : ""}
              href="javascript:void(0)"
              onClick={() => {
                handleRedirections("/sportsbook/Cricket");
              }}
            >
              <img src={SportsIcon} alt="evolution" />
              <span>Sports</span>
            </a>
          </li>
          <li>
            <a
              className={activePage === "sportsbook" ? "active" : ""}
              onClick={() => {
                handleRedirections("/sportsbook/Inplay");
              }}
            >
              <img src={ExchangeIcon} alt="exchange" />
              <span>Inplay</span>
            </a>
          </li>
          <li className="center">
            <a
              className={activePage === "sports" ? "active" : ""}
              onClick={() => {
                handleRedirections("/sports");
              }}
            >
              <img src={HomeIcon} alt="home" />
            </a>
          </li>
          <li>
            <a
              className={activePage === "casino" ? "active" : ""}
              onClick={() => {
                handleRedirections("/Casino");
              }}
            >
              <img src={EzugiIcon} alt="ezugi" />
              <span>Casino</span>
            </a>
          </li>

          <li align="end" className=" DropDownFooter">
            <a
              className={activePage === "evolution" ? "active" : ""}
              onClick={() => {
                handleRedirections("/profile");
              }}
            >
              <img src={UserIcon} alt="evolution" />
              <span>Profile</span>
            </a>
          </li>
        </ul>
      </div>
      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </>
  );
}
