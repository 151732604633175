import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import logo from "../assets/images/logo.png";
import AppuGames from "../assets/images/provider/Betfair.jpg";
import EvolutionGames from "../assets/images/provider/provider-logo-evolution.png";
import EzugiGames from "../assets/images/provider/provider-logo-ezugi.png";
import { useSelector } from "react-redux";

import FbIconImg from "../assets/images/icons/login/facebook.png";
import InstaIconImg from "../assets/images/icons/login/instagram.png";
import WhatsaPPImg from "../assets/images/icons/login/Whatsapp.png";
import TelegramImg from "../assets/images/icons/login/Telegram.png";

import binance from "../assets/images/payments/new/Binance.png";
import therthnt from "../assets/images/payments/new/Tether.png";
import Bitcoin from "../assets/images/payments/new/Bitcoin.png";
import Phonepay from "../assets/images/payments/new/phonepe-logo-icon.png";
import Paytm from "../assets/images/payments/new/Paytm.png";
import GooglaPay from "../assets/images/payments/new/Gpay.png";
import UPIcon from "../assets/images/payments/new/Upi.png";

import AgePlus from "../assets/images/icons/18plus.png";
import GamCare from "../assets/images/icons/gamecare.png";
import GT from "../assets/images/icons/gt.png";

import Whatsapp from "../assets/images/Whatsapp.svg";
import Gmail from "../assets/images/icons/gmail.png";
import Phone from "../assets/images/icons/tell.png";

const BeforeLoginFooter = () => {
  const { appDetails } = useSelector((state) => state.app);
  return (
    <>
      <footer className="footer">
        <Container>
          <div className="sectionDivide">
            <div className="singleSection">
              <div className="footer-logo">
                <a href="/">
                  <img src={appDetails?.LOGO_URL} alt="Logo" />
                </a>
              </div>

              <div className="foot-discription">
                <p>
                  We are dedicated to making sure you have a great time playing
                  games on Jack777 while also being well aware of the negative
                  social and financial implications of excessive gaming. We
                  provide and advertise our games as delightful forms of
                  entertainment because we think that only when players play
                  sensibly and in moderation will gaming remain as such.
                </p>
              </div>
            </div>
            <div className="singleSection">
              <div className="providers_logo">
                <h4>100% Safe & instant payments</h4>
                <p>
                  You can make payments and receive earnings instantly via your
                  UPI ID - so you can be sure that your money is safe and
                  secure. All UPI platforms are accepted.
                </p>
              </div>
              <div className="paymentspartner">
                <ul>
                  <li>
                    <img src={AgePlus} />
                  </li>
                  <li>
                    <img src={GamCare} />
                  </li>
                  <li>
                    <img src={GT} />
                  </li>
                </ul>
              </div>
            </div>
            <div className="singleSection">
              <div className="providers_logo">
                <h4>Accepted modes of payments</h4>
              </div>
              <div className="paymentspartner paymentSection">
                <ul>
                  <li>
                    <img src={UPIcon} />
                  </li>
                  <li>
                    <img src={GooglaPay} />
                  </li>
                  <li>
                    <img src={Paytm} />
                  </li>
                  <li>
                    <img src={Phonepay} />
                  </li>
                  <li>
                    <img src={Bitcoin} />
                  </li>
                  <li>
                    <img src={therthnt} />
                  </li>

                  <li>
                    <img src={binance} />
                  </li>
                </ul>
              </div>
              <div className="providers_logo ">
                <h4>Contact Us</h4>
                <div className="paymentspartner ContactFooter">
                  <ul>
                    <li>
                      <a className="panel" href="mailto:777jackco@gmail.com">
                        <img src={Gmail} alt="whatsapp" />
                        <span>777jackco@gmail.com</span>
                      </a>
                    </li>
                    <li>
                      <a className="panel" href="">
                        <img src={Phone} alt="phone" />
                        <span>{appDetails?.PHONE}</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="panel"
                        href={
                          appDetails?.WHATS_APP_URL != ""
                            ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                            : "javascript:void(0)"
                        }
                        target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                      >
                        <img src={Whatsapp} alt="whatsapp" />
                        <span>{appDetails?.PHONE}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>

        <div className="footer-links">
          <div className="social-icons">
            <ul>
              <li>
                <a
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={FbIconImg} />
                  <span>Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={InstaIconImg} />
                  <span>Instagram</span>
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={TelegramImg} />
                  <span>Telegram</span>
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={WhatsaPPImg} />
                  <span>Whatapp</span>
                </a>
              </li>
            </ul>
          </div>
          <ul>
            <li>
              <a href="/contacts">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicys">Privacy Policy</a>
            </li>

            <li>
              <a href="/ResponsibleGamings">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplays">Fair Play</a>
            </li>
            <li>
              <a href="/gameruless">Games Rules</a>
            </li>
            <li>
              <a href="/termsandconditions">Terms and Conditions</a>
            </li>
          </ul>
        </div>
      </footer>
      <div className="copyright">© 2024 Jack777. All Rights Reserved.</div>
    </>
  );
};

export default BeforeLoginFooter;
