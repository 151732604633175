import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, Nav, Navbar, Button, Dropdown } from "react-bootstrap";

import AviatorImg from "../assets/images/icons/aviator.png";

import MenuIcon from "../assets/images/icons/leftbar/list.png";
import UserIcon from "../assets/images/user-icon.svg";
import MoonLight from "../assets/images/moon.svg";
import SunLight from "../assets/images/sunny.svg";
import WalletIcon from "../assets/images/icons/wallet-icon.png";
import ProfileIcon from "../assets/images/icons/menus/user.png";
import MyBetsIcon from "../assets/images/icons/menus/my-bets.png";
import BonusIcon from "../assets/images/icons/menus/bonus.png";
import ReferralIcon from "../assets/images/icons/menus/referral.png";
import AcStatementIcon from "../assets/images/icons/menus/statement.png";
import LogoutIcon from "../assets/images/icons/menus/logout.png";

import { redirect, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import { useOutsideAlerter } from "../hooks/useOutsideClick";

import BalanceIcon from "../assets/images/icons/wallet.png";
import ArrowIcon from "../assets/images/icons/down-arrow.png";
import MoreIcon from "../assets/images/icons/more.png";

import Referral from "../assets/images/icons/menus/referral.png";
import Bonus from "../assets/images/icons/menus/gift.png";
import Statement from "../assets/images/icons/menus/statement.png";
import Logout from "../assets/images/icons/menus/logout.png";
import HistoryIcon from "../assets/images/icons/menus/history.png";
import WithdrawIcon from "../assets/images/icons/menus/withdraw.png";

import numberWithCommas from "../utils/numbersWithComma";

const HeaderAfterLogin = () => {
  const location = useLocation();
  const { user, wallet, balance } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(!show1);
  const dispatch = useDispatch();
  const [activePage, setActivePage] = useState("");
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const catMenu = useRef(null);
  console.log("catMenu 2");
  useOutsideAlerter(catMenu, setShow1);

  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);

  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const htmlElement = document.documentElement;
  useEffect(() => {
    if (show1) {
      htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
  }, [show1]);

  const headerMenu = [
    "home",
    "sportsbook",
    "Casino",
    "promotion",
    "aviator",
    "/",
    "ESports",
  ];
  useEffect(() => {
    console.log("location", location.pathname);
    const matchedValue = headerMenu.find((f) => location?.pathname.includes(f));
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  // console.log("balance", wallet);
  return (
    <>
      <header className="header afterlogin">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <Button
                    variant="primary menu-btn"
                    onClick={handleShow1}
                    className="me-2 d-block d-md-none"
                    id="menuSidebarHandler"
                  >
                    <img
                      src={MenuIcon}
                      alt="User Menu Icon"
                      width={25}
                      id="menuSidebarHandlerImg"
                    />
                  </Button>

                  <div className="logo">
                    <a href="/sports">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                      {/* <img src={logo} alt="Logo" /> */}
                    </a>
                  </div>
                  <ul className="">
                    <li>
                      <GamesSearchModal />
                    </li>
                  </ul>
                </div>

                <div className="header-left d-none d-lg-block">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    className="d-none"
                  />
                  <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                    <Nav className="me-auto">
                      {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                      <Nav.Link
                        href="/sportsbook/Inplay"
                        className={activePage === "sportsbook" ? "active" : ""}
                      >
                        <span>Sports</span>
                      </Nav.Link>
                      <Nav.Link
                        href="/Casino"
                        className={activePage === "Casino" ? "active" : ""}
                      >
                        <span>Casino</span>
                      </Nav.Link>
                      <Nav.Link
                        href="/promotion"
                        className={activePage === "promotion" ? "active" : ""}
                      >
                        <span>Promotion</span>
                      </Nav.Link>
                      <Nav.Link
                        href="/sportsbook/ESports"
                        className={activePage === "ESports" ? "active" : ""}
                      >
                        <span>E-Sports</span>
                      </Nav.Link>
                      <Nav.Link
                        href="/casino/spribe/aviator"
                        className={activePage === "aviator" ? "active" : ""}
                      >
                        <img
                          src={AviatorImg}
                          alt="Aviator"
                          className="aviator"
                        />
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </div>

                <div className="headerRight">
                  <div class="dropdownSection">
                    <div className="dropdown">
                      <div className="d-flex align-items-center">
                        <img
                          src={BalanceIcon}
                          alt="BalanceIcon"
                          className="d-none d-md-block"
                        />
                        {wallet?.balance}
                      </div>
                      <div className="Dropdownicon">
                        <img src={ArrowIcon} alt="BalanceIcon" />
                      </div>
                    </div>
                    <ul class="dropdown-menu">
                      <li>
                        <a href="javascript:void(0)">
                          Cash (₹) <span>{numberWithCommas(wallet?.cash)}</span>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          Bonus (₹){" "}
                          <span>{numberWithCommas(wallet?.coins)}</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="DepositSection">
                    <a href="/gatewaylist">
                      <img src={MoreIcon} alt="MoreIcon" />
                      Deposit
                    </a>
                  </div>

                  <Dropdown align="end" className=" DropDownOption">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <img src={UserIcon} alt="profile" />{" "}
                      <span>{user?.mstruserid} </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="/profile">
                        <img src={UserIcon} alt="profile" />
                        <span>Profile</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/withdraw">
                        <img src={WithdrawIcon} alt="bets" />
                        <span>Withdraw</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/mybets">
                        <img src={HistoryIcon} alt="bets" />
                        <span>History</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/referral">
                        <img
                          src={Referral}
                          alt="referral"
                          style={{ padding: "0 2px" }}
                        />
                        <span>Referral</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/bonus">
                        <img src={Bonus} alt="bonus" />
                        <span>Bonuses</span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/cashier">
                        <img src={Statement} alt="statement" />
                        <span>Account Statement</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        href="/"
                        onClick={() => {
                          dispatch(logoutUser());
                        }}
                        className="logout"
                      >
                        <img
                          src={Logout}
                          alt="logout"
                          style={{ width: "18px" }}
                        />
                        <span>Logout</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        {show1 && <MobLeftbar className="d-block d-md-none" ref={catMenu} />}
      </header>
    </>
  );
};

export default HeaderAfterLogin;
