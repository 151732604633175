import React, { useEffect, useRef, useState } from "react";
import { Button, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import { APP_CONST } from "../config/const";
import { CustomView, isMobile } from "react-device-detect";
import { useOutsideAlerter } from "../hooks/useOutsideClick";

import AviatorImg from "../assets/images/icons/aviator.png";
import MenuIcon from "../assets/images/icons/leftbar/list.png";
import MobLeftbar from "./MobLeftbar";
import GamesSearchModal from "../views/components/GamesSearchModal";
import ModalWrapper from "./ModalWrapper";

import hoverAviator from "../assets/images/icons/aviatorhover.gif";

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showAuthModals, setShowAuthModals] = useState(false);
  const [defaultModal, setDefaultModal] = useState("login");
  const catMenu = useRef(null);

  useOutsideAlerter(catMenu, setShow1);

  const handleShow1 = () => setShow1(!show1);

  useEffect(() => {
    const isClassAddedLocalStorage = localStorage.getItem("isClassAdded");
    if (isClassAddedLocalStorage) {
      setIsClassAdded(true);
      document.body.classList.add("light-theme");
    }
  }, []);

  const toggleClass = () => {
    if (isClassAdded) {
      setIsClassAdded(false);
      localStorage.removeItem("isClassAdded");
      document.body.classList.remove("light-theme");
    } else {
      setIsClassAdded(true);
      localStorage.setItem("isClassAdded", true);
      document.body.classList.add("light-theme");
    }
  };

  const handleModalShow = (modalName) => {
    // setShowAuthModals((p) => ({ ...p, [modalName]: true }));
    setShowAuthModals(true);
    setDefaultModal(modalName);
  };

  const handleModalClose = (modalName) => {
    // setShowAuthModals((p) => ({ ...p, [modalName]: false }));
    setShowAuthModals(false);
  };

  return (
    <header className="header header_bfrlgn">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <Button
                  variant="primary menu-btn"
                  onClick={handleShow1}
                  className="d-block d-md-none"
                  id="menuSidebarHandler"
                >
                  <img
                    src={MenuIcon}
                    alt="User Menu Icon"
                    width={25}
                    id="menuSidebarHandlerImg"
                  />
                </Button>

                <div className="logo">
                  <a href="/">
                    <img src={appDetails?.LOGO_URL} alt="Logo" />
                    {/* <img src={logo} alt="Logo" /> */}
                  </a>
                </div>
                <div className="">
                  <GamesSearchModal />
                </div>
              </div>

              <div className="header-left ms-4 d-none d-lg-block">
                <Navbar.Toggle
                  aria-controls="basic-navbar-nav"
                  className="d-none"
                />
                <Navbar.Collapse id="basic-navbar-nav" className="d-block">
                  <Nav className="me-auto">
                    {/* {<Nav.Link href="#home">New Games</Nav.Link>} */}
                    <Nav.Link
                      href="#"
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <span>Sports</span>
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <span>Casino</span>
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <span>Promotion</span>
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <span>E-Sports</span>
                    </Nav.Link>
                    <Nav.Link
                      href="#"
                      onClick={() => {
                        handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                      }}
                    >
                      <img src={AviatorImg} alt="Aviator" className="aviator" />
                      <span className="hoverAviator">
                        <img src={hoverAviator} alt="hoverAviator" />
                      </span>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>

              <div className="headerRight">
                <Button
                  variant="primary"
                  // onClick={() => {
                  //   navigate("/sign-in");
                  // }}
                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                  className="login_btn me-2"
                >
                  SIGN IN
                </Button>
                <Button
                  variant="primary"
                  // onClick={() => {
                  //   navigate("/mobile-number");
                  // }}

                  onClick={() => {
                    handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                  }}
                  className="signup_btn"
                >
                  REGISTER
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAuthModals && (
        <ModalWrapper
          defaultShow={defaultModal}
          setShowAuthModals={setShowAuthModals}
        />
      )}
      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
      {show1 && (
        <MobLeftbar
          ref={catMenu}
          className="d-block d-md-none"
          setShowAuthModals={setShowAuthModals}
        />
      )}
    </header>
  );
};

export default Header;
