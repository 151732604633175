import React, { Component } from "react";
import Slider from "react-slick";
import BannerImg1 from "../assets/images/banner/banner-home1.png";

const MainSlider = ({ banners }) => {
  const settings = {
    arrows: false,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    autoplay: true,

    slidesToShow: 1,
    slidesToScroll: 1,

    speed: 3000,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="">
      <Slider {...settings}>
        {banners &&
          banners.length &&
          banners.map((b, index) => {
            return (
              <div className="img_box" key={index}>
                <img src={b.link} alt="First Slide" />
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default MainSlider;
