import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import HomeIcon from "./../../assets/images/icons/leftbar/home.png";
import InplayIcon from "./../../assets/images/icons/leftbar/inplay.png";
import CricketIcon from "./../../assets/images/icons/leftbar/cricket.png";
import SoccerIcon from "./../../assets/images/icons/leftbar/soccer.png";
import TennisIcon from "./../../assets/images/icons/leftbar/tennis.png";
import Greyhoundracing from "./../../assets/images/icons/leftbar/greyhound-racing.png";
import Horseracing from "./../../assets/images/icons/leftbar/horse-racing.png";
import Basketball from "./../../assets/images/icons/leftbar/basketball.png";
import Politics from "./../../assets/images/icons/leftbar/politics.png";
import TableTennis from "./../../assets/images/icons/leftbar/table-tennis-equipment.png";
import IceHockey from "./../../assets/images/icons/leftbar/ice-hockey.png";
import Archery from "./../../assets/images/icons/leftbar/target.png";
import MotorSports from "./../../assets/images/icons/leftbar/motorcycle.png";

import { APP_CONST } from "../../config/const";

const LeftBarSports = ({ setShowAuthModals }) => {
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  const { sportsData, oddsData, showBetSlip } = useSelector(
    (state) => state.sports
  );

  const handleEventClick = (event) => {
    navigate(
      `/detail-page/${event?.marketSlug}/${event?.matchid}/${event?.marketid}/${event?.SportId}`
    );
  };

  const tabs = [
    {
      id: 1,
      eventKey: "1",
      name: "In play",
    },
    {
      id: 2,
      eventKey: "2",
      name: "Cricket",
    },
    {
      id: 3,
      eventKey: "3",
      name: "Soccer",
    },
    {
      id: 4,
      eventKey: "4",
      name: "Tennis",
    },
  ];

  const handleModalShow = (modalName) => {
    if (setShowAuthModals) {
      setShowAuthModals(true);
    }
  };

  return (
    <>
      <div className="leftbarSec d-none d-md-block">
        <ul>
          <li>
            <a
              href="#"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Inplay");
              }}
            >
              <img src={HomeIcon} alt="home" />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Inplay");
              }}
            >
              <img src={InplayIcon} alt="inplay" />
              <span>In-play</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Cricket");
              }}
            >
              <img src={CricketIcon} alt="cricket" />
              <span>Cricket</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Soccer");
              }}
            >
              <img src={SoccerIcon} alt="soccer" />
              <span>Soccer</span>
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Tennis");
              }}
            >
              <img src={TennisIcon} alt="tennis" />
              <span>Tennis</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Ice%20Hockey");
              }}
            >
              <img src={IceHockey} alt=" Icon" />
              <span>Ice Hockey</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Motor%20Sports");
              }}
            >
              <img src={MotorSports} alt=" Icon" />
              <span>Motor Sports</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Archery");
              }}
            >
              <img src={Archery} alt=" Icon" />
              <span>Archery</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Basketball");
              }}
            >
              <img src={Basketball} alt=" Icon" />
              <span>Basketball</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Politics");
              }}
            >
              <img src={Politics} alt=" Icon" />
              <span>Politics</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/Horse%20Racing");
              }}
            >
              <img src={Horseracing} alt=" Icon" />
              <span>Horse Racing</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0)"
              onClick={() => {
                !isAuth
                  ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                  : (window.location.href = "/sportsbook/GreyHond%20Racing");
              }}
            >
              <img src={Greyhoundracing} alt=" Icon" />
              <span>Grey Hound Racing</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LeftBarSports;
