import React from "react";
import { useSelector } from "react-redux";

import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import FbIconImg from "../../../../assets/images/icons/login/facebook.png";
import InstaIconImg from "../../../../assets/images/icons/login/instagram.png";
import WhatsaPPImg from "../../../../assets/images/icons/login/Whatsapp.png";
import TelegramImg from "../../../../assets/images/icons/login/Telegram.png";

const Promotion = () => {
  const { appDetails } = useSelector((state) => state.app);

  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <main className="main">
        <div className="PromotionPageSection">
          <div className="logo">
            <a href="/">
              <img src={appDetails?.LOGO_URL} alt="Logo" />
              {/* <img src={logo} alt="Logo" /> */}
            </a>
          </div>
          <h2>WR'RE COMING SOON</h2>
          <p>
            We are coming soon. We are working hard to give you the best
            experience
          </p>
          <div className="social-icons">
            <ul>
              <li>
                <a
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={FbIconImg} />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={InstaIconImg} />
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={TelegramImg} />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={WhatsaPPImg} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Promotion;
