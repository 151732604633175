import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import {
  checkIfDemoUserExist,
  demoLogin,
  loginUser,
} from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { APP_CONST } from "../../../config/const";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

import PaymentLogo from "../../../assets/images/banner/login-payment.png";
import LoginImg from "../../../assets/images/banner/login-img-new.png";

import FbIconImg from "../../../assets/images/icons/login/facebook.png";
import InstaIconImg from "../../../assets/images/icons/login/instagram.png";
import WhatsaPPImg from "../../../assets/images/icons/login/Whatsapp.png";
import TelegramImg from "../../../assets/images/icons/login/Telegram.png";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);

  const [isDemoUserExist, setIsDemoUserExist] = useState(false);

  useEffect(() => {
    checkDemoUser();
  }, []);

  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      dispatch(
        loginUser(loginData, () =>
          handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.AUTO)
        )
      );
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const loginDemoUser = (e) => {
    e.preventDefault();
    dispatch(
      demoLogin(() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.AUTO))
    );
  };

  return (
    <>
      <Modal
        show={show}
        className={"steps-canvas login-modal"}
        onHide={() => {
          console.log("working");
        }}
        placement={"end"}
      >
        <Modal.Header
          closeButton
          onClick={() => handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.MANUAL)}
          closeVariant="black"
        >
          <Modal.Title>
            {" "}
            <img
              className="login-modal-img"
              src={appDetails?.LOGO_URL}
              alt="Logo"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="login-section">
            <div className="Login-leftPart">
              <img src={LoginImg} alt="LoginImg" />
            </div>
            <div className="Login-rightPart">
              <div className="form-steps">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Control
                      type="text"
                      placeholder="Enter Username*"
                      name="username"
                      onChange={handleChange}
                      isInvalid={!!errors.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors?.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formBasicPassword"
                  >
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password*"
                      name="password"
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                    />
                    <span
                      className="icon"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {errors?.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-end mb-3">
                    <a
                      className="form-link"
                      href="javascript:void(0)"
                      onClick={() => {
                        handleClose(
                          APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD,
                          "auto"
                        );
                        handleModalShow(APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD);
                      }}
                    >
                      Password Recovery
                    </a>
                  </div>
                  <Button
                    className="btn_primary"
                    variant=""
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Sign In
                  </Button>
                  {isDemoUserExist && (
                    <div className="mt-2">
                      <Button
                        className="btn_primary"
                        variant=""
                        type="submit"
                        onClick={loginDemoUser}
                      >
                        Sign In with Demo ID
                      </Button>
                    </div>
                  )}
                </Form>

                <div className="sign-up ">
                  Don't have an account?
                  <a
                    href="#"
                    onClick={() => {
                      handleClose(APP_CONST.AUTH_MODAL.LOGIN, "auto");
                      handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                    }}
                  >
                    Register
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="Payment-social-logo">
            <ul>
              <li>
                <img src={PaymentLogo} alt="paymentlogo" />
              </li>
            </ul>
            <ul className="social-icons">
              <li>
                <a
                  href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                  target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
                >
                  <img src={FbIconImg} />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                  }
                  target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
                >
                  <img src={InstaIconImg} />
                </a>
              </li>
              <li>
                <a
                  href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                  target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
                >
                  <img src={TelegramImg} />
                </a>
              </li>
              <li>
                <a
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <img src={WhatsaPPImg} />
                </a>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      {show && <div className="modal-overlay"></div>}
    </>
  );
};
export default LoginModal;
