import React, { useEffect, useLayoutEffect, useRef } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { getBanners } from "../../../../redux/app/actions";
import { useDispatch } from "react-redux";
import { useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Header from "../../../../containers/Header";
import Footer from "../../../../containers/Footer";
import MainSlider from "../../../../containers/MainSlider";
import { casinoTabsNew as casinoTabs } from "../../../../lib/data/casinoNew.data";
import ImageCard from "../../../components/imageCard";
import { AiOutlineSearch } from "react-icons/ai";
import { BsFillCloudFill } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { gsap } from "gsap";

import CasinoBanner from "../../../../assets/images/casino-banner.jpg";
import EzugiProvider from "../../../../assets/images/providers/Ezugiprovider.png";
import EvolutionProvider from "../../../../assets/images/providers/EvolutionProvider.png";
import VivoProvider from "../../../../assets/images/providers/Vivoprovider.png";
import XPGProvider from "../../../../assets/images/providers/XPGprovi.png";
import SNProvider from "../../../../assets/images/providers/Supernowaprovider.png";
import Slider from "react-slick";
import CategorisedCasino from "./categorisedCasino";
import LeftBarSports from "../../../components/LeftBarSports";
import RightBarCasino from "../../../components/RightBarCasino";
import BottomNav from "../../../../containers/BottomNav";

const Casino = () => {
  const { isAuth } = useSelector((state) => state.auth);
  const container = useRef();
  const tl = useRef();
  const [isSearched, setIsSearched] = useState(null);
  const [searchedText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [activeCategory, setActiveCategory] = useState("Overview");
  const [selectedProvider, setSelectedProvider] = useState({
    id: 1,
    name: "All",
    eventKey: "all",
  });
  const [activeTab, setActiveTab] = useState({
    id: 1,
    name: "All Games",
    // key: "allGames",
    key: "popularGames",
    // imgSrc: RouletteCat,
  });
  const [customActiveTab, setCustomActiveTab] = useState(null);
  const [allGamesData, setAllGamesData] = useState(null);
  const categoriesTab = [
    "Overview",
    "Roulette",
    "Blackjack",
    "Baccarat",
    "Poker",
    "Spribe",
    "Vivo",
    // "Supernowa",
    // "PragmaticPlay",
    "TV Games",
    "All Games",
  ];
  const tabData = Object.keys(casinoTabs);
  console.log("tabData", tabData);
  const tabsArray = [
    {
      id: 1,
      name: "Roulette",
      key: "roulettetab",
    },
    {
      id: 2,
      name: "Baccarat",
      key: "baccarattab",
    },
    {
      id: 3,
      name: "Blackjack",
      key: "blackjacktab",
    },
    {
      id: 4,
      name: "Poker",
      key: "pokertab",
    },
    {
      id: 5,
      name: "Andar Bahar",
      key: "andarbahartab",
    },
    {
      id: 6,
      name: "Dragontiger",
      key: "dragontigertab",
    },
  ];
  const getTabActiveClass = (tab) => {
    if (customActiveTab) {
      return tab.key === customActiveTab.key;
    }
    return tab.key === activeTab?.key;
  };
  const filterAllGamesData = (provider = selectedProvider?.eventKey) => {
    let emptyArray = {};
    Object.keys(casinoTabs).forEach((key) => {
      if (
        key !== "allGames" &&
        tabsArray.some((s) => s.key === key) &&
        typeof casinoTabs[key] !== "string"
      ) {
        emptyArray = {
          ...emptyArray,
          ...Object.values(casinoTabs[key]).reduce((acc, value) => {
            return { ...acc, ...value };
          }, {}),
        };
      }
    });
    let filteredValues;
    if (provider !== "all") {
      filteredValues = Object.values(emptyArray).filter(
        (f) => f.providerName?.toLowerCase() === provider
      );
    } else {
      filteredValues = Object.values(emptyArray).filter(
        (f) =>
          // f.providerName?.toLowerCase() === "evolution" ||
          f.providerName?.toLowerCase() === "ezugi" ||
          f.providerName?.toLowerCase() === "vivo"
      );
    }
    console.log("filteredValues", filteredValues);
    setAllGamesData(filteredValues);
  };
  const providersArray = [
    { id: 1, name: "All", eventKey: "all" },
    // { id: 2, name: "Evolution", eventKey: "evolution" },
    { id: 3, name: "Ezugi", eventKey: "Ezugi" },
    { id: 4, name: "Evolution", eventKey: "Evolution" },
  ];
  useEffect(() => {
    filterAllGamesData();
  }, []);
  const manageActiveTab = (provider) => {
    setCustomActiveTab({
      id: 1,
      name: "All Games",
      key: "allGames",
      // imgSrc: RouletteCat,
    });
    filterAllGamesData(provider.eventKey);
    // console.log("tabToBeActive", tabToBeActive);
  };

  const handleSearch = (e) => {
    setSearchText(e?.target?.value);
    const value = e?.target?.value?.toLowerCase();

    if (value) {
      setIsSearched(true);
      let filteredValues = [];

      Object.values(casinoTabs).forEach((slide) => {
        if (slide.name !== "All Games") {
          Object.values(slide).map((item, index) => {
            if (typeof item !== "string") {
              let response = Object.values(item).filter(
                (f) =>
                  f?.name
                    ?.toLowerCase()
                    .trim()
                    ?.indexOf(value.toLowerCase().trim()) > -1
              );
              let clone = [...filteredValues];
              clone = [...clone, ...response];
              filteredValues = clone;
            }
          });
        }
      });

      setFilteredData(filteredValues);
    } else {
      setIsSearched(false);
    }
  };

  const settings1 = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const boxes = self.selector(".dot");
      tl.current = gsap
        .timeline()
        .to(boxes[0], { x: 250 })

        .reverse();
    }, container); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  }, []);

  const toggleTimeline = () => {
    tl.current.reversed(!tl.current.reversed());
  };

  const settings = {
    dots: false,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      {isAuth ? (
        <HeaderAfterLogin toggleDefault={false} />
      ) : (
        <Header Casino={true} />
      )}

      <main className="main d-flex GamesSection SlotsSectionNew onlycasinogame">
        <LeftBarSports />
        <div className="middleContentSec">
          <div className="mainBanner">
            {/* <MainSlider banners={banners} /> */}
            <img src={CasinoBanner} alt="casin banner" />
          </div>

          <CategorisedCasino />

          <Footer />
        </div>

        <RightBarCasino />
        <BottomNav />
      </main>
    </>
  );
};

export default Casino;
