import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalWrapper from "../../containers/ModalWrapper";
import { APP_CONST } from "../../config/const";

export default function CasinoCard(data) {
  const { isAuth } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [showAuthModal, setShowAuthModal] = useState(false);
  let game = data && data.data;

  const handleClick = (url) => {
    if (isAuth) {
      navigate(url);
    } else {
      setShowAuthModal(true);
    }
  };

  return (
    <>
      <li onClick={() => handleClick(game.redirectUrl)}>
        <a href={"#"} className="casino_games">
          <img src={game?.imgUrl} alt={game?.alt} />
          <span>{game?.name}</span>
        </a>
      </li>
      {showAuthModal && (
        <ModalWrapper
          setShowAuthModals={setShowAuthModal}
          defaultShow={APP_CONST.AUTH_MODAL.LOGIN}
        />
      )}
    </>
  );
}
